import { Disclaimer } from "@tawenda-npm/tawenda-disclaimer";
import "lazysizes";
import { AdBlock } from "@tawenda-npm/tawenda-adblock";
import { PlansQ } from "@tawenda-npm/tawenda-plansq";
import { Popunder } from "@tawenda-npm/tawenda-popunder";
import { UserState } from "../libs/UserState";
import lazyFrame from "../libs/lazyFrame";
import { DropDownHandler } from "../libs/DropDown";
import { FidelityOffer } from "@tawenda-npm/tawenda-fidelity-offer";
import { toggleClass } from "@tawenda-npm/tawenda-utils/lib/index";
import initBackButton from "@tawenda-npm/tawenda-back-button";
import { caughtPromiseAll } from "../libs/promises";
import searchHandler from "../libs/Search";
import DalenysSubscription from "../libs/DalenysSubscription";

export class Page {
  protected readonly disclaimer: Disclaimer;
  protected readonly popunder: Popunder;
  protected readonly dalenysModal: HTMLElement | undefined;
  protected readonly dalenysBanner: HTMLElement | undefined;

  constructor() {
    this.popunder = new Popunder(document.documentElement.dataset.popunderUrl);
    this.disclaimer = new Disclaimer();
    this.disclaimer.addEventListener("closed", async () => {
      await this.popunder.redirectToPopUnder();
    });
    this.dalenysModal = document.querySelector("[data-dalenys-modal]");
    this.dalenysBanner = document.querySelector("[data-dalenys-banner]");
  }

  public async start(): Promise<void> {
    const promisesToCall = [
      this.disclaimer.loadDisclaimer(),
      this.popunder.setupDOM(),
      new AdBlock(document.documentElement.getAttribute("lang")).showAegis(),
    ];

    searchHandler();

    const userStateHandler: HTMLElement = document.querySelector(
      "[data-user-state-url]"
    );
    if (userStateHandler) {
      promisesToCall.push(
        new UserState(userStateHandler.dataset.userStateUrl).setupDOM()
      );
    }

    await caughtPromiseAll(promisesToCall);

    lazyFrame();
    initBackButton("jckm");
    if (this.dalenysModal || this.dalenysBanner) {
      new DalenysSubscription(this.dalenysBanner ?? this.dalenysModal).show();
    }

    const plansQDesktop: HTMLElement = document.querySelector(
      "[data-plans-q-desktop]"
    );
    if (plansQDesktop) new PlansQ(plansQDesktop).show();

    const plansQMobile: HTMLElement = document.querySelector(
      "[data-plans-q-mobile]"
    );
    if (plansQMobile) new PlansQ(plansQMobile).show();

    const mobileMenuToggle = document.querySelectorAll(
        "[data-nav-mobile-toggle]"
      ),
      mobileNavbar: HTMLElement = document.querySelector(
        "[data-navbar-mobile]"
      );
    mobileMenuToggle.forEach((el: HTMLElement) => {
      el.addEventListener("click", (e: Event) => {
        e.preventDefault();
        toggleClass(mobileNavbar);
      });
    });

    const dropdownHandlersIds = [
      "dropdown-menu-categories",
      "dropdown-menu-user",
      "dropdown-mobile-search",
    ];
    dropdownHandlersIds.forEach((id: string) => {
      const dropDown = document.getElementById(id);
      if (dropDown) {
        new DropDownHandler(dropDown);
      }
    });

    const fidelityOfferWrapper: HTMLElement = document.querySelector(
      "[data-widget-come-back]"
    );
    if (fidelityOfferWrapper) {
      const fidelityOffer = new FidelityOffer({
        element: fidelityOfferWrapper,
        endpoints: {
          create: document.documentElement.dataset.fidelityOfferUrlCreate,
          update: document.documentElement.dataset.fidelityOfferUrlUpdate,
          check: document.documentElement.dataset.fidelityOfferUrlCheck,
        },
      });
      fidelityOffer.init();
    }
  }
}
